// React
import React from "react";
import PropTypes from "prop-types";

// General
import Dashboard from "../dashboard/Dashboard";
import MemoizedMap from "../maps/Map";
import ProjectDashboardImage from "../dashboard-components/ProjectDashboardImage";
import PDCPProjectTeam from "../dashboard-components/PDCPProjectTeam";
import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import Spinner from "../common/Spinner";

import PostContractComponent from "./PostContractComponent";
import PostContractCommentary from "./PostContractCommentary";
import DecisionsAdvice from "../dashboard-components/DecisionsAdvice";
import DashboardRisks from "../dashboard-components/RisksAdvice";

import { usePostContract } from "../../api/post-contract/PostContractAPI";

PostContractDashboard.propTypes = {
  project: PropTypes.object,
  dashboardType: PropTypes.string,
  showDashboardDrawer: PropTypes.bool,
  setShowDashboardDrawer: PropTypes.func,
  dashboardSettings: PropTypes.object,
  selectedReport: PropTypes.object,
};

export default function PostContractDashboard(props) {
  // Project
  const { project } = props;

  // Dashboard
  const { dashboardType } = props;
  const { showDashboardDrawer, setShowDashboardDrawer } = props;
  const { dashboardSettings } = props;

  // Post Contract
  const { selectedReport } = props;

  // Post Contract Data
  const { postContract } = usePostContract(
    selectedReport?.ProjectId,
    selectedReport?.Id
  );

  if (dashboardType !== "POST_CONTRACT") {
    return null;
  }

  if (postContract.loading) {
    return (
      <div className="post-contract-background-loading">
        <Spinner />
      </div>
    );
  }

  if (isEmpty(selectedReport)) {
    return null;
  }

  // Create Dashboard IDs
  // Project
  const IMAGE = "IMAGE";
  const MAP = "MAP";
  const TEAM = "TEAM";

  // Cost Report
  const BUDGET = "BUDGETSUMMARY";
  const FORECASTCOST = "FORECASTCOST";
  const CONTINGENCYBALANCE = "CONTINGENCYBALANCE";
  const HEADROOMTOBUDGET_CHART = "HEADROOMTOBUDGET_CHART";
  const CONTINGENCYBALANCE_CHART = "CONTINGENCYBALANCE_CHART";
  const DETAILS = "DETAILSPANEL";

  // Variations
  const VARI = "VARIATIONPANEL";
  const CRITICALVARIATIONS = "CRITICALVARIATIONS";
  const CHANGETYPE = "CHANGETYPE";
  const CHANGETYPE_TABLE = "CHANGETYPE_TABLE";

  // History
  const TRENDCHART = "TRENDCHART";
  const COMPARISONCHART = "COMPARISONCHART";
  const COMPARISONTABLE = "COMPARISONTABLE";

  // Commentary
  const DECISIONS = "DECISIONS";
  const RISKS = "RISKS";
  const NOTES = "NOTES";
  const GLOSSARY = "GLOSSARY";
  const EXCLUSIONS = "EXCLUSIONS";
  const PCCOMMENT = "PCCOMMENTARY";

  // Progress Claim
  const TRACKER = "TRACKER";
  const RECOMMENDATION = "RECOMMENDATION";
  const TIMEBAR = "TIMEBARGRAPH";

  //Provisional Sums
  const CRITICALPROVISIONALSUMS = "CRITICALPROVISIONALSUMS";
  const PROVISIONALSUMSDONUT = "PROVISIONALSUMSDONUT";
  const PSSUMMARY = "PSSUMMARY";
  const PSSTATUS = "PSSTATUS";

  //Client Directs
  const CDCOSTSUMMARY = "CDCOSTSUMMARY";

  // Risk Register
  const RRTYPEGRAPH = "RRTYPEGRAPH";
  const RRTYPETABLE = "RRTYPETABLE";
  const CRITICALRR = "CRITICALRR";

  // The Components
  const display = [
    <PostContractComponent
      key={CONTINGENCYBALANCE_CHART}
      dashboardID={CONTINGENCYBALANCE_CHART}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"HorizontalBarCharts"}
      sectionType={"Cost Report"}
      componentName={"Balance of Contingency Chart"}
      title={"Balance of Contingency"}
    />,
    <PostContractComponent
      key={RRTYPEGRAPH}
      dashboardID={RRTYPEGRAPH}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"DonutCharts"}
      sectionType={"Cost Report"}
      componentName={"Risk Types Chart"}
      title={"Risk Types"}
    />,
    <PostContractComponent
      key={RRTYPETABLE}
      dashboardID={RRTYPETABLE}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Risk Types"}
      title={"Risk Types"}
    />,
    <PostContractComponent
      key={CRITICALVARIATIONS}
      dashboardID={CRITICALVARIATIONS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Critical Variations"}
      title={"Critical Variations"}
    />,
    <PostContractComponent
      key={CRITICALPROVISIONALSUMS}
      dashboardID={CRITICALPROVISIONALSUMS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Critical Provisional Sums"}
      title={"Critical Provisional Sums"}
    />,
    <PostContractComponent
      key={CRITICALRR}
      dashboardID={CRITICALRR}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Critical Risks"}
      title={"Critical Risks"}
    />,
    <PostContractComponent
      key={PSSUMMARY}
      dashboardID={PSSUMMARY}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"CostBudgetCharts"}
      sectionType={"Cost Report"}
      componentName={"Headroom to Budget Chart"}
      title={"Headroom to Budget"}
    />,
    <PostContractComponent
      key={PSSTATUS}
      dashboardID={PSSTATUS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Provisional Sums Status"}
      title={"Provisional Sums Status"}
    />,
    <PostContractComponent
      key={COMPARISONCHART}
      dashboardID={COMPARISONCHART}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"BarCharts"}
      sectionType={"Cost Report"}
      componentName={"Cost Report Comparison Chart"}
      title={"Cost Report Comparison"}
    />,
    <PostContractComponent
      key={COMPARISONTABLE}
      dashboardID={COMPARISONTABLE}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Cost Report Comparison Table"}
      title={"Cost Report Comparison Table"}
    />,
    <PostContractComponent
      key={BUDGET}
      dashboardID={BUDGET}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Budget Summary Table"}
      title={"Budget Summary"}
    />,
    <PostContractComponent
      key={FORECASTCOST}
      dashboardID={FORECASTCOST}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Forecast Cost Summary Table"}
      title={"Forecast Cost Summary"}
    />,
    <PostContractComponent
      key={CONTINGENCYBALANCE}
      dashboardID={CONTINGENCYBALANCE}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Headroom to Budget Table"}
      title={"Headroom to Budget Table"}
    />,
    <PostContractComponent
      key={TRENDCHART}
      dashboardID={TRENDCHART}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"BarCharts"}
      sectionType={"Cost Report"}
      componentName={"Cost Report Trends Chart"}
      title={"Cost Report Trends"}
    />,
    <PostContractComponent
      key={DETAILS}
      dashboardID={DETAILS}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"ReportDetailsCharts"}
      sectionType={"Cost Report"}
      componentName={"Cost Report Details Chart"}
      title={"Cost Report Details"}
    />,

    <PostContractComponent
      key={VARI}
      dashboardID={VARI}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Variation Status Table"}
      title={"Variation Status"}
    />,
    <PDCPProjectTeam
      title={translate("Project Team")}
      key={TEAM}
      dashboardID={TEAM}
      project={project}
    />,
    <ProjectDashboardImage
      title={translate("Project Image")}
      key={IMAGE}
      dashboardID={IMAGE}
      project={project}
    />,
    <MemoizedMap
      title={translate("Project Location")}
      key={MAP}
      dashboardID={MAP}
      lat={project.lat}
      lng={project.lng}
    />,

    <PostContractComponent
      key={CHANGETYPE}
      dashboardID={CHANGETYPE}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"DonutCharts"}
      sectionType={"Cost Report"}
      componentName={"Change Type Summary Chart"}
      title={"Change Type Summary"}
    />,
    <PostContractComponent
      key={CHANGETYPE_TABLE}
      dashboardID={CHANGETYPE_TABLE}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Change Type Summary Table"}
      title={"Change Type Summary"}
    />,
    <PostContractComponent
      key={PROVISIONALSUMSDONUT}
      dashboardID={PROVISIONALSUMSDONUT}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"DonutCharts"}
      sectionType={"Cost Report"}
      componentName={"Provisional Sums Summary Chart"}
      title={"Provisional Sums Summary"}
    />,
    <PostContractComponent
      key={HEADROOMTOBUDGET_CHART}
      dashboardID={HEADROOMTOBUDGET_CHART}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"CostBudgetCharts"}
      sectionType={"Cost Report"}
      componentName={"Headroom to Budget Chart"}
      title={"Headroom to Budget"}
    />,
    <PostContractComponent
      key={CDCOSTSUMMARY}
      dashboardID={CDCOSTSUMMARY}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"CostBudgetCharts"}
      sectionType={"Cost Report"}
      componentName={"Client Direct Costs Summary Chart"}
      title={"Client Direct Costs Summary"}
    />,
    <PostContractComponent
      key={RECOMMENDATION}
      dashboardID={RECOMMENDATION}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TableComponents"}
      sectionType={"Cost Report"}
      componentName={"Progress Payment Recommendation Table"}
      title={"Progress Payment Recommendation"}
    />,
    <PostContractComponent
      key={TIMEBAR}
      dashboardID={TIMEBAR}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"TimeBarComponents"}
      sectionType={"Cost Report"}
      componentName={"Time Elapsed Bar"}
      title={"Time Elapsed"}
    />,

    <PostContractComponent
      key={TRACKER}
      dashboardID={TRACKER}
      postContract={postContract}
      selectedReport={selectedReport}
      componentType={"HorizontalBarCharts"}
      sectionType={"Cost Report"}
      componentName={"Contract Tracker Chart"}
      title={"Contract Tracker"}
    />,

    <DecisionsAdvice
      title={"Contractual Glossary"}
      key={GLOSSARY}
      dashboardID={GLOSSARY}
      decisions={postContract.data[0]?.Advice.DecisionsGlossary}
      type={"glossary"}
      column1={"decisions-advice-glossary-term"}
      column2={"decisions-advice-glossary-definition"}
      isGlossary
    />,
    <DecisionsAdvice
      title={"Decisions Required"}
      key={DECISIONS}
      dashboardID={DECISIONS}
      decisions={postContract.data[0]?.Advice.DecisionsGlossary}
      type={"decision"}
      column1={"decisions-advice-decision"}
      column2={"decisions-advice-required_by"}
      isDecision
    />,
    <PostContractCommentary
      key={EXCLUSIONS}
      dashboardID={EXCLUSIONS}
      commentary={postContract.data[0]?.Advice.ExclusionsClarificationsProgress}
      type={"cost_report_exclusions"}
      title={"Exclusions"}
    />,

    <PostContractCommentary
      key={NOTES}
      dashboardID={NOTES}
      commentary={postContract.data[0]?.Advice.ExclusionsClarificationsProgress}
      type={"cost_report_clarifications"}
      title={"Notes and Clarifications"}
    />,
    <DashboardRisks
      title={translate("Risks / Opportunities")}
      key={RISKS}
      dashboardID={RISKS}
      risks={postContract.data[0]?.Advice.Risks}
    />,
  ];

  const column1Set = [
    RRTYPEGRAPH,
    RRTYPETABLE,
    CRITICALRR,
    BUDGET,
    FORECASTCOST,
    CONTINGENCYBALANCE,
    CRITICALPROVISIONALSUMS,
    HEADROOMTOBUDGET_CHART,
    TIMEBAR,
    TRACKER,
  ];
  const column2Set = [
    NOTES,
    EXCLUSIONS,
    DETAILS,
    VARI,
    CRITICALVARIATIONS,
    COMPARISONCHART,
    COMPARISONTABLE,
  ];
  const column3Set = [
    IMAGE,
    MAP,
    TEAM,
    RECOMMENDATION,
    CONTINGENCYBALANCE_CHART,
  ];
  const column4Set = [
    DETAILS,

    BUDGET,
    FORECASTCOST,

    CONTINGENCYBALANCE,
    HEADROOMTOBUDGET_CHART,

    CRITICALVARIATIONS,
    CRITICALRR,
    CRITICALPROVISIONALSUMS,

    VARI,

    TRENDCHART,
    COMPARISONCHART,
    COMPARISONTABLE,

    DECISIONS,
    RISKS,
    NOTES,
    EXCLUSIONS,
    PCCOMMENT,
    GLOSSARY,

    CHANGETYPE,
    CHANGETYPE_TABLE,
    PROVISIONALSUMSDONUT,

    PSSUMMARY,
    PSSTATUS,

    CDCOSTSUMMARY,

    RRTYPEGRAPH,
    RRTYPETABLE,

    IMAGE,
    TEAM,
    MAP,
  ];

  return (
    <div>
      <Dashboard
        display={display}
        column1Set={column1Set}
        column2Set={column2Set}
        column3Set={column3Set}
        column4Set={column4Set}
        project={project}
        // Dashboard Settings
        showDashboard={showDashboardDrawer}
        setShowDashboard={setShowDashboardDrawer}
        dashboardSettings={dashboardSettings}
        dashboardType={"POST_CONTRACT"}
      />
    </div>
  );
}
