// React
import React from "react";
import PropTypes from "prop-types";

// Components
import TableWrapper from "./TableWrapper";

// Style
import "./Table.css";
import isEmpty from "../../validation/is-empty";
import { useConfigureSettings } from "../../api/dashboards/ConfigureAPI";

Table.propTypes = {
  title: PropTypes.string,
  moduleId: PropTypes.string,
  discriminator: PropTypes.string,
};

export default function Table(props) {
  const { moduleId, discriminator, title } = props;

  const settings = useConfigureSettings(moduleId, discriminator);

  if (settings.configureSettings.loading) {
    return null;
  }

  if (isEmpty(settings.configureSettings.data)) {
    let jsonData = [
      {
        Discriminator: discriminator,
        configSettings: {
          layout: null,
          table: [
            {
              discriminator: title,
              id: "",
              Columns: [],
            },
          ],
        },
      },
    ];
    settings.configureSettings.data = jsonData;
  }
  return (
    <TableWrapper
      moduleId={moduleId}
      discriminator={discriminator}
      configureSettings={settings}
      {...props}
    />
  );
}
