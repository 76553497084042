import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";

// API
import { pc2APIs } from "../api-endpoints/PostContract2.0Endpoints";
import isEmpty from "../../validation/is-empty";

export function usePostContractForm(
  postContractFormType,
  projectId,
  packageId
) {
  const [loading, setLoading] = useState(true);
  const [postContractForm, setPostContractForm] = useState({});

  useEffect(() => {
    if (!isEmpty(packageId)) {
      setLoading(true);
      getPostContractForm(postContractFormType, projectId, packageId)
        .then((data) => {
          setPostContractForm(data);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  }, [postContractFormType, projectId, packageId]);

  return {
    postContractForm: {
      data: postContractForm,
      loading: loading,
      get: getPostContractForm,
      set: setPostContractForm,
    },
  };
}

export async function getPostContractForm(
  postContractFormType,
  projectId,
  packageId
) {
  // POST CONTRACT API
  let url = "";
  if (postContractFormType === "packageFields") {
    url = pc2APIs().read_packages + "?projectId=" + projectId;
  }

  if (postContractFormType === "contractFields") {
    url =
      pc2APIs().read_contract_fields +
      "?projectId=" +
      projectId +
      "&packageId=" +
      packageId;
  }

  if (postContractFormType === "sectionFields") {
    url =
      pc2APIs().read_section_fields +
      "?projectId=" +
      projectId +
      "&packageId=" +
      packageId +
      "&source=SECTION";
  }
  if (postContractFormType === "navigationFields") {
    url =
      pc2APIs().read_section_fields +
      "?projectId=" +
      projectId +
      "&packageId=" +
      packageId +
      "&source=NAVIGATION";
  }

  if (postContractFormType === "costReportFields") {
    url =
      pc2APIs().read_upload_form +
      "?projectId=" +
      projectId +
      "&packageId=" +
      packageId +
      "&formName=Cost Report";
  }

  if (postContractFormType === "progressClaimFields") {
    url =
      pc2APIs().read_upload_form +
      "?projectId= " +
      projectId +
      "&packageId=" +
      packageId +
      "&formName=Progress Claim";
  }

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    console.log("getPostContractForm api error");
  }

  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log("getPostContractForm response error");
  }
  return response;
}

export function updateFieldErrors(fields, setFieldErrors) {
  setFieldErrors([]);

  fields.forEach((field) => {
    if (field.isMandatory && field.value === "") {
      setFieldErrors((fieldErrors) => {
        fieldErrors.push(field.name);
        return fieldErrors;
      });
    }
  });
}

export async function updatePostContractForm(data) {
  const { fields } = data;
  const { setErrors } = data;
  const { urlEndpoint } = data;
  const { setModal } = data;
  const { stateUpdateFunction } = data;
  const { setFieldErrors } = data;

  const formData = new FormData();

  let formFields = [];

  fields.forEach((field) => {
    if (field.dataType !== "file") {
      formFields.push(field);
    }

    if (field.dataType === "file") {
      formData.append("file", field.value);
    }
  });

  formData.append("field", JSON.stringify(formFields));

  setErrors({
    text: "Saving...",
    type: "feedback-success",
  });

  // POST CONTRACT API
  let url = urlEndpoint;

  const config = {
    method: "POST",
    body: formData,
    headers: authHeader({ authJson: false, authForm: true, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    console.log("Upload Post Contract Form Error");
  }

  if (response.ok) {
    await stateUpdateFunction();

    setErrors({
      text: "Created Successfully",
      type: "feedback-success",
    });

    setModal(false);
  } else if (response.status === 422) {
    let errors = await response.json();
    setFieldErrors(errors.data);
    return setErrors({
      text: "Please Check Fields",
      type: "feedback-error",
    });
  } else {
    return setErrors({
      text: "Save Error",
      type: "feedback-error",
    });
  }
}
