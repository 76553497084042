import isEmpty from "../../validation/is-empty";

export function countReports(projects) {
  let totalCostPlans = 0;
  let totalCostReports = 0;

  projects.forEach((proj) => {
    proj.CostPlanNumbers = 0;
    let countCP = 0;

    if (!isEmpty(proj.CPs)) {
      proj.CPs.data.forEach((cp) => {
        countCP += cp.versions.length;
      });
      proj.CostPlanNumbers = countCP;
    }

    totalCostPlans += countCP;
    totalCostReports += proj.total_cost_reports;
  });

  return {
    totalCostPlans,
    totalCostReports,
  };
}
export function getPortfolioCountForEachUser(accounts,benchmarkingPortfolio){
  accounts.forEach(element=> {
    let lstAccounts = benchmarkingPortfolio.filter(item=>item.userGlobalId===element.id)  
     element.collectionCount= isEmpty(lstAccounts[0]?.benchmarkids?.length)? 0 : lstAccounts[0]?.benchmarkids?.length;
});
}