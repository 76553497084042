import "./ImageUpload.css";
import PropTypes from "prop-types";
import { ImageDisplay } from "./ImageDisplay";

ImageUpload.propTypes = {
  imagePreview: PropTypes.string,
  setImage: PropTypes.func,
  setImagePreview: PropTypes.func,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  isContain: PropTypes.bool,
  isBezierDisabled: PropTypes.bool,
};

export function ImageUpload(props) {
  const { imagePreview, setImage } = props;
  const { setImagePreview } = props;
  const { imageWidth, imageHeight } = props;
  const { isContain } = props;
  const { isBezierDisabled } = props;

  return (
    <div>
      <div className={"image-upload-preview"}>
        <ImageDisplay
          image={imagePreview}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          isContain={isContain}
          isBezierDisabled={isBezierDisabled}
        />
      </div>

      <div className="image-upload-button-container">
        <label className="general-modal-button">
          <input
            id={"image-upload-button"}
            type="file"
            className="image-upload-button"
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                setImage(e.target.files[0]);
                setImagePreview(window.URL.createObjectURL(e.target.files[0]));
              }
            }}
          />
          {"Attach Image"}
        </label>
      </div>
    </div>
  );
}
