import React, { useState } from "react";
import PropTypes from "prop-types";
import Table from "../table/Table";
import { benchmarkAccountsColumns } from "./AccountManagementTable.functions";
import { displayUserImage } from "../../validation/image-clean";
import Spinner from "../common/Spinner";
import { tranStr } from "../../utils/translation";

AccountsTable.propTypes = {
  accounts: PropTypes.object,
};

export default function AccountsTable(props) {
  const { accounts} = props;
  const [selectedAccount, setSelectedAccount] = useState({});
  const [ modal,setModal] = useState(false);

  if (accounts.loading) {
    return (
      <div className="project-management-content">
        <Spinner marginLeft={"625px"} marginTop={"200px"} />
      </div>
    );
  }
  const users = accounts.data.map((u) => {
    u.image = displayUserImage(u);
    return u;
  });
debugger;
  return (
    <div className="company-management-content">
      <Table
        key={"accountsTable1"}
        title={tranStr("Accounts")}
        tableArray={users}
        columns={benchmarkAccountsColumns()}
        tableSize={15}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Row Selection
        selectedRow={selectedAccount}
        setSelectedRow={setSelectedAccount}
        // Modal Control
        setModal={setModal}
        modal={modal}
      />
    </div>
  );
}
