import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";

// API
import { pc2APIs } from "../api-endpoints/PostContract2.0Endpoints";

export function usePackages(projectId) {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    setLoading(true);
    getPackages(projectId)
      .then((data) => {
        setPackages(data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [projectId]);

  return {
    packages: {
      data: packages,
      loading: loading,
      get: getPackages,
      set: setPackages,
    },
  };
}

export async function getPackages(projectId) {
  // POST CONTRACT API
  const url = pc2APIs().read_packages + "?projectId=" + projectId;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };

  let response = [];
  try {
    response = await fetch(url, config);
    //Add new get here
  } catch (e) {
    console.log("getPackages api error");
  }

  if (response.ok) {
    response = await response.json();

    const packageData = [];
    response.packageFields.forEach((parent) => {
      parent.options.forEach((row) => {
        const pack = {
          Id: row.id,
          PackageName: row.options.find((x) => x.name === "Package Name").value,
          ContractType: row.options.find((x) => x.name === "Contract Type")
            .value,
          ProjectId: projectId,
          HasContractFields: row.value.toLowerCase() === "true",
        };
        packageData.push(pack);
      });
    });

    response = packageData;
  } else {
    response = [];
    console.log("getPackages response error");
  }
  return response;
}
