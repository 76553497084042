import React from "react";
import PropTypes from "prop-types";

import { translate } from "../../utils/translation";

import { filterBenchmarks, getBaseYearCollection } from "../benchmarking-project-selection/BenchmarkSelectionFilter.functions";

import BenchmarkingTable from "../benchmarking-project-selection/BenchmarkingTable";
import BenchmarkSelectionFilter from "../benchmarking-project-selection/BenchmarkSelectionFilter";
import { FormError, SaveForm } from "./BenchmarkCreate.components";

import "../benchmarking-project-selection/BenchmarkingTable.css";
import "../benchmarking-project-selection/ModalBenchmarkingSelection.css";

/// props validation | SQ(javascript:S6774)
BenchmarkCreateSelectionModal.propTypes = {
  benchmarks: PropTypes.array,
  setBenchmarks: PropTypes.func,
  benchmarkSelectionFilters: PropTypes.object,
  setBenchmarkSelectionFilters: PropTypes.func,
  benchmarkSelection: PropTypes.array,
  setBenchmarkSelection: PropTypes.func,
  benchmarkSelectionPage: PropTypes.number,
  setBenchmarkSelectionPage: PropTypes.func,
  user:PropTypes.object
};
///
export default function BenchmarkCreateSelectionModal(props) {
  const { benchmarks } = props;
  const { benchmarkSelectionFilters, setBenchmarkSelectionFilters } = props;
  const { benchmarkSelection, setBenchmarkSelection ,user} = props;

  // filter data to be supplied to BenchmarkingTableComponent
  const filteredData = filterBenchmarks(
    null,
    benchmarks,
    benchmarkSelectionFilters
  );

  return (
    <div className="benchmark-create-selection-modal">
      <div className="benchmark-selection-content-container">
        <div className="benchmark-selection-table-filter-container">
          <h1 className="display-4">{translate("Benchmark Filters")}</h1>
          <BenchmarkSelectionFilter
            filters={benchmarkSelectionFilters}
            setFilters={setBenchmarkSelectionFilters}
            disableQuickBenchmarks={true}   
            sortedBaseYear={getBaseYearCollection(benchmarks) }       
          />
        </div>
        <div className="benchmark-selection-table-container">
          <h1 className="display-4">
            {translate("Benchmark Selection Table")}
          </h1>
          <BenchmarkingTable
            data={filteredData}
            selection={benchmarkSelection}
            setSelection={setBenchmarkSelection}
            user={user}
          />
          <div className="general-row-container">&nbsp;</div>
          <div className="general-row-container">
            <FormError {...props} />
          </div>
          <div className="general-row-container">
            <SaveForm {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}
