import React, {   useState } from "react";
import PropTypes from "prop-types";

import "./BenchmarkingDisplayTable.css";
import isEmpty from "../../validation/is-empty";

/// props validation | SQ(javascript:S6774)
Toggle.propTypes = {
    isEscalated: PropTypes.bool,
    setIsEscalated: PropTypes.func,
    caller: PropTypes.string,
  };
  export function Toggle(props) {
    const { isEscalated, setIsEscalated, caller } = props;
  
    if (caller !== "MASTER_BENCHMARKING") return null;
  
    const visible = isEscalated ? "Escalated" : "Not Escalated";
  
    return (
      <div className="escalated-switch">
        <label className="switch">
          <input
            type="checkbox"
            checked={isEscalated}
            onChange={() => {
              setIsEscalated(!isEscalated);
            }}
            id="escalated-switch"
          />
          <span className="slider round" />
          <span className="tooltiptext">{visible}</span>
        </label>
      </div>
    );
  }
  
  /// props validation | SQ(javascript:S6774)
  WarningTooltip.propTypes = {
    className: PropTypes.string,
    isHovered: PropTypes.bool,
    setIsHovered: PropTypes.func,
    message: PropTypes.any,
  };
  ///
  function WarningTooltip(props) {
    const { className } = props;
    const { isHovered, setIsHovered } = props;
    const { message } = props;
  
    return (
      <button
        className={`${className} ${isHovered ? "show-tooltip" : ""}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <i className="fas fa-exclamation-triangle" />
        <span className="tooltip">{message}</span>
      </button>
    );
  }
  /// props validation | SQ(javascript:S6774)
  GetWarning.propTypes = {
    benchmark: PropTypes.object,
    isGlobalBenchmarking: PropTypes.bool,
    skipHighlight: PropTypes.bool,
    country: PropTypes.string,
    cellText:PropTypes.string
  };
  
  export function GetWarning(props)
  {
    const {benchmark,isGlobalBenchmarking,skipHighlight,country,cellText}=props
    const [isProjectWarningHovered, setIsProjectWarningHovered] = useState(false);
    const [isLocationWarningHovered, setIsLocationWarningHovered] =useState(false);
   
    if (isEmpty(benchmark)) {
      return cellText;
    }
  
    let projectwarning = null;
    let locationwarning = null;
  
    if (!skipHighlight && benchmark.country !== country && !isGlobalBenchmarking) {
      projectwarning = (
        <WarningTooltip
          className={"project-warning-icon"}
          isHovered={isProjectWarningHovered}
          setIsHovered={setIsProjectWarningHovered}
          message={
            "Comparison projects have not been adjusted for location, since location factors are not applied across countries."
          }
        />
      );
    }
  
    if (
      isGlobalBenchmarking &&
      (benchmark.globalFactorNotFoundForBenchmarkLocation ||
        benchmark.globalFactorNotFoundForProjectLocation)
    ) {
      let message = benchmark.globalFactorNotFoundForBenchmarkLocation
        ? "Global factor cannot be found for this project's city."
        : "Global factor cannot be found for the selected city.";
  
      projectwarning = (
        <WarningTooltip
          className={"project-warning-icon"}
          isHovered={isProjectWarningHovered}
          setIsHovered={setIsProjectWarningHovered}
          message={message}
        />
      );
    }
  
    if (!skipHighlight && benchmark.locationWarning) {
      locationwarning = (
        <WarningTooltip
          className={"location-warning-icon"}
          isHovered={isLocationWarningHovered}
          setIsHovered={setIsLocationWarningHovered}
          message={"Locality factor cannot be found for this project."}
        />
      );
    }
    return( <>{cellText} {projectwarning} {locationwarning}</>);
  }
  
  export function highLightFirstRow(skipHighlight,caller,seconds,setSeconds) {
    if (
     // !isEmpty(document.getElementsByClassName("bm_display_table")) &&
      !skipHighlight &&
      caller === "COST_PLANNING"
    ) {
      setInterval(() => {
        if (seconds === 100) {
          setSeconds(0);
        } else {
          setSeconds(seconds + 1);
        }
      }, 500);
      const elems = document.getElementsByClassName("pct-row");
      if(!isEmpty(elems)){
          elems[0].style.backgroundColor = "var(--collaboration2)";
          elems[0].style.color = "white";
          elems[0].style.borderTop = "1px solid white";
          elems[0].style.fontWeight = "bold";
          elems[0]
            .querySelectorAll("button")
            .forEach((tag) => {
              tag.style.color = "white";
              tag.style.fontWeight = "bold";
            });
      }
    }
  }
  
  export function generateTotal(bms, cellKey) {
    const bmCellKeyAndValues = bms.map((bm) => bm[cellKey]);
    const total = bmCellKeyAndValues.reduce(
      (acc, curr) => parseInt(acc) + parseInt(curr)
    );
    return total;
  }