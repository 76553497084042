import React, { useEffect } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";

import "./PostContractSelector.css";

import PostContractSlider from "../post-contract-selector/PostContractSlider";
import { PostContractPackageSelector } from "./PostContractSelector.components";

PostContractSelector.propTypes = {
  dashboardType: PropTypes.string,
  packages: PropTypes.object,
  selectedPackage: PropTypes.object,
  setSelectedPackage: PropTypes.func,
  postContractReports: PropTypes.object,
  selectedReport: PropTypes.object,
  setSelectedReport: PropTypes.func,
};

export default function PostContractSelector(props) {
  const { dashboardType } = props;

  // Packages
  const { packages } = props;
  const { selectedPackage, setSelectedPackage } = props;

  // Reports
  const { postContractReports } = props;
  const { selectedReport, setSelectedReport } = props;

  // Set Default Package
  useEffect(() => {
    if (!isEmpty(packages.data)) {
      if (isEmpty(selectedPackage)) {
        const pack = packages.data[0];
        setSelectedPackage(pack);
      } else {
        const selectedPack = packages.data.filter(
          (p) => p.Id === selectedPackage.Id
        )[0];
        setSelectedPackage(selectedPack);
      }
    }
  }, [setSelectedPackage, packages.data, selectedPackage]);

  // Set Default Report
  useEffect(() => {
    if (!isEmpty(postContractReports.data)) {
      const latestPC =
        postContractReports.data[postContractReports.data.length - 1];
      setSelectedReport(latestPC);
    } else {
      setSelectedReport({});
    }
  }, [setSelectedReport, postContractReports.data]);

  // Only Display if POST_CONTRACT
  if (
    window.location.href.includes("dashboard") &&
    dashboardType !== "POST_CONTRACT"
  ) {
    return null;
  }

  // Do not display in Cost Planning
  if (window.location.href.includes("cost-planning")) {
    return null;
  }

  return (
    <div className="post-contract-selector-container">
      <div className="post-contract-package-selector">
        <PostContractPackageSelector
          packages={packages}
          selectedPackage={selectedPackage}
          setSelectedPackage={setSelectedPackage}
        />
      </div>
      <div className="post-contract-selector">
        <PostContractSlider
          loading={postContractReports.loading}
          data={postContractReports.data}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
        />
      </div>
    </div>
  );
}
