// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import Table from "../table/Table";
import BenchmarkView from "./BenchmarkView";
import Modal from "../modal/Modal";
import Spinner from "../common/Spinner";

// Functions
import { tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";
import {
  getDecoratedData,
  getRoleBasedColumns,
} from "./BenchmarkControlTable.functions";
// Style
import "./BenchmarkControlTable.css";
import { columns } from "../benchmarking-project/BenchmarkingTable.functions";

BenchmarkControlTable.propTypes = {
  selectedBenchmarks: PropTypes.array,
  setSelected: PropTypes.func,
  data: PropTypes.array,
  user: PropTypes.object,
  sensitivityLevelCodes: PropTypes.object,
  setSelectedMenuColumns:PropTypes.func
};

export default function BenchmarkControlTable(props) {
  const { selectedBenchmarks, setSelected, user, sensitivityLevelCodes } =
    props;
  const { data } = props;
  const {setSelectedMenuColumns}=props;

  const [selectedRow, setSelectedRow] = useState({});
  const [modal, setModal] = useState(false);

  if (isEmpty(data) || sensitivityLevelCodes.loading) {
    return (
      <div className="benchmark-control-table-container">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Table
        title={tranStr("Benchmarking")}
        tableArray={getDecoratedData(data, sensitivityLevelCodes)}
        columns={getRoleBasedColumns(user, columns())}
        tableSize={15}
        isTitleDisabled={true}
        isTotalDisabled={true}
        // Checked Selection
        checked={selectedBenchmarks}
        setChecked={setSelected}
        // Row Selection
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        // Modal Control
        setModal={setModal}       
        discriminator="MASTER_BENCHMARKING"
        moduleId={user.id}
        isMenuAvailable={true}
        setSelectedMenuColumns={setSelectedMenuColumns}
      />      
      <Modal
        // Modal Props
        title={"Selected Item"}
        Component={BenchmarkView}
        modal={modal}
        setModal={setModal}
        // Component Props
        selectedRow={selectedRow}
        user={user}
      />
    </div>
  );
}
