import React from "react";
import isEmpty from "../../validation/is-empty";
import { ImageDisplay } from "../images/ImageDisplay";
import { displayInsightImage } from "../../validation/image-clean";
import "./CategoryProfile.css";
import PropTypes from "prop-types";

CategoryProfile.propTypes = {
  selectedTopic: PropTypes.object,
};

export default function CategoryProfile(props) {
  const { selectedTopic } = props;

  let subLocation = determineSubLocation();

  if (subLocation !== "articles") {
    return null;
  }

  if (isEmpty(selectedTopic)) {
    return null;
  }

  return (
    <div className="category-profile">
      <div className="article-profile-image">
        <ImageDisplay
          key={selectedTopic.id}
          image={displayInsightImage(selectedTopic)}
          imageWidth={"160px"}
          imageHeight={"200px"}
          isBezierDisabled={true}
        />
        <div className="article-profile-image-banner">
          <h1 className="display-4" style={{ fontSize: "20px" }}>
            {selectedTopic.title}
          </h1>
        </div>
      </div>
    </div>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
