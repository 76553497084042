import React, { useState } from "react";
import CompanyAdd from "./CompanyEdit";
import Modal from "../modal/Modal";
import { translate } from "../../utils/translation";

import "./CompanyManagementComponents.css";

export default function CompanyManagementComponents(props) {
  const { companies } = props;

  // Hide if not Summary
  let subLocation = determineSubLocation();
  if (subLocation !== "company-control") {
    return null;
  }

  return (
    <div className="company-management-control-box">
      <div className="display-4" style={{ fontSize: "20px" }}>
        {translate("Company Controls")}
      </div>
      <div className="project-management-control-panel-content">
        <AddButton companies={companies} />
      </div>
    </div>
  );
}

function AddButton(props) {
  const { companies } = props;
  const [modal, setModal] = useState(false);
  return (
    <div className="company-management-control-panel-row">
      <Modal
        // Modal Props
        title={translate("Add Company")}
        Component={CompanyAdd}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        label={translate("Add Company")}
        companies={companies}
      />
      <button
        className="general-upload-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Add Company")}
      </button>
    </div>
  );
}

function determineSubLocation() {
  const URL = window.location.href;

  return URL.substring(URL.lastIndexOf("/") + 1);
}
