// React
import React, { useState } from "react";

// Functions
import { filterBenchmarks, getBaseYearCollection } from "./BenchmarkSelectionFilter.functions";

// Components
import BenchmarkingSelection from "./BenchmarkingSelection";
import Modal from "../modal/Modal";
import { hasRoles } from "../../utils/roles";
import { tranStr } from "../../utils/translation";

// Style
import "./ModalBenchmarkingSelection.css";

export default function ModalBenchmarkingSelection(props) {
  // Props
  const { user } = props;
  const { benchmarkName } = props;
  const { projectID } = props;
  const { estimateID } = props;
  const { benchmarkingForBenchmark } = props;
  const { CPs } = props;
  const { CP } = props;
  const { estimate } = props;
  const { project } = props;
  // All benchmarking
  const { benchmarking, setBenchmarking } = props;

  // State
  const [modal, setModal] = useState(false);
  const [benchmarkSelectionFilters, setBenchmarkSelectionFilters] = useState(
    {}
  );

  // Filter Data
  const filteredBenchmarks = filterBenchmarks(
    projectID,
    benchmarking,
    benchmarkSelectionFilters
  );

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  const isProjectPhaseCostPlanning = project.project_phase === "COST_PLANNING";

  return (
    <div className="modalbenchmark-popup-button-container">
      <button
        title={
          isProjectPhaseCostPlanning
            ? ""
            : "Select Benchmarks menu is not available as the project is not in the Cost Planning phase."
        }
        className={`modalbenchmark-popup-button ${
          isProjectPhaseCostPlanning ? "" : "disabled"
        }`}
        onClick={() => {
          if (isProjectPhaseCostPlanning) {
            setModal(true);
          }
        }}
      >
        <i className="fas fa-tasks" />
      </button>
      <Modal
        // Modal Props
        title={tranStr("Select Benchmarks for") + " " + benchmarkName}
        Component={BenchmarkingSelection}
        modal={modal}
        setModal={setModal}
        // Component Props
        user={user}
        projectID={projectID}
        estimateID={estimateID}
        estimate={estimate}
        CP={CP}
        CPs={CPs}
        filteredBenchmarks={filteredBenchmarks}
        benchmarkingForBenchmark={benchmarkingForBenchmark}
        benchmarkSelectionFilters={benchmarkSelectionFilters}
        setBenchmarkSelectionFilters={setBenchmarkSelectionFilters}
        setBenchmarking={setBenchmarking}
        project={project}
        sortedBaseYear={getBaseYearCollection(benchmarking)}
      />
    </div>
  );
}
