import React from "react";
import "./BenchmarkProjectInfoView.css";
import PropTypes from "prop-types";
import ContractorOnCostComparisonChart from "../charts/ContractorOnCostComparisonChart";
import CostBenchmarking from "../charts/CostBenchmarking";
import AverageCostCategories from "../benchmarking-project/AverageCostCategories";

BenchmarkVisualsView.propTypes = {
  rowData: PropTypes.object,
};
export default function BenchmarkVisualsView(props) {
  const { rowData } = props;  

  rowData?.category_groups?.forEach((element) => {
    element?.categoryItems?.forEach((item) => {
      item.description = item?.field;
      item.displayed_rate = item?.localRegionRate;
    });
  });

  return (
    <div className="create-benchmark-container">
      <div className="create-benchmark-form-container">
        <div className="visuals-view">
          <ContractorOnCostComparisonChart
            thisBenchmark={rowData}
            benchmarks={[]}
            cbs={{ data: { categoryGroups: rowData.category_groups } }}
            title={""}
          />
          <CostBenchmarking
            unitOfMeasure={"m2"}
            thisBenchmark={rowData}
            benchmarks={[]}
          />
          <AverageCostCategories
            thisBenchmark={rowData}
            benchmarks={[]}
            title={rowData.project_name}
            caller={"MASTER_BENCHMARKING"}
          />
        </div>
      </div>
    </div>
  );
}
