import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "../modal/Modal";
import { BA_CBS_CM_ROLE, hasRoles } from "../../utils/roles";
import PropTypes from "prop-types";

import "./BenchmarkUploadModal.css";
import BenchmarkView from "./BenchmarkView";

/// props validation | SQ(javascript:S6774)
BasicBenchmarkModal.propTypes = {
  setBenchmarking: PropTypes.func,
  user: PropTypes.object,
  roles: PropTypes.object,
  auth: PropTypes.object,
};

function BasicBenchmarkModal(props) {
  const { setBenchmarking } = props;
  const { user } = props.auth;

  const [modal, setModal] = useState(false);

  if (!hasRoles(user.roles, BA_CBS_CM_ROLE)) {
    return null;
  }

  return (
    <div>
      <Modal
        // Modal Props
        title={"Basic Benchmark"}
        Component={BenchmarkView}
        modal={modal}
        setModal={setModal}
        // Component Props
        setBenchmarking={setBenchmarking}
        setShow={setModal}
        caller="BASIC_BENCHMARK_CREATE"
        {...props}
      />
      <button
        className="benchmark-control-upload-button"
        onClick={() => {
          setModal(true);
        }}
      >
        <i className="fas fa-save" />
      </button>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(BasicBenchmarkModal);
