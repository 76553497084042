// React
import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// API
import { useTPI } from "../../api/tender-price-index/TenderPriceIndexingAPI";
import { useInsights } from "../../api/general/MarketInsightsAPI";
import {
  useLocationFactors,
  useLocations,
} from "../../api/admin/RegionManagementAPI";

// Navigation
import TenderPriceNavigation from "./TenderPriceNavigation";
import Insight from "../market-insights/Insight";
import Article from "../market-insights/Article";

// Sections
import MarketInsights from "../market-insights/MarketInsights";
import TenderPriceIndexingManagement from "../tender-price-indexing/TenderPriceIndexingManagement";
import { MarketInsightsUpload } from "../market-insights/MarketInsightsUpload";
import ArticleUpload from "../market-insights/InsightArticleUpload";
import CategoryDelete from "./CategoryDelete";
import CategoryEdit from "./CategoryEdit";
import CategoryProfile from "./CategoryProfile";

//Location factor components
import LocationFactors from "./location-factors/LocationFactors";
import LocationFactorsUpdate from "./location-factors/LocationFactorsUpdate";

import ArticleProfile from "./ArticleProfile";

// TPI Components
import TenderPriceIndexingUpload from "./TenderPriceIndexingUpload";
import TenderPriceIndexingDelete from "./TenderPriceIndexingDelete";
import TenderPriceIndexingCalculator from "./TenderPriceIndexingCalculator";
import TenderPriceIndexLocationSelect from "./TenderPriceIndexLocationSelect";

import "./TenderPriceIndex.css";

// Utilities
import isEmpty from "../../validation/is-empty";
import { connect } from "react-redux";
import CurrencyExchange from "./currency-exchange/CurrencyExchange";
import CurrencyExchangeUpdate from "./currency-exchange/CurrencyExchangeUpdate";
import { useCurrencyExchange } from "../../api/admin/CurrencyExchangeAPI";

function TenderPriceIndex(props) {
  // User
  const { user } = props.auth;

  // TPI
  const [region, setRegion] = useState(user.region);
  const [country, setCountry] = useState(user.country);
  const { TPI, setTPI } = useTPI(true, region, country, false);
  const { locations } = useLocations(true);
  const { locationFactors } = useLocationFactors();
  const [selectedLocations, setLocations] = useState([]);
  const { currencyExchange } = useCurrencyExchange();
  // If TPI region or country changes reset the selected locations
  useEffect(() => {
    setLocations([]);
  }, [country, region]);

  //Location list with unique city names
  let locationList = locationFactors.data
    .filter((x) => x.country === country && x.region === region)
    .filter((value, index, self) => {
      return self.findIndex((v) => v.city === value.city) === index;
    });

  // Insights
  const { insights, insightsLoading, setInsights } = useInsights();

  let articles = [];

  if (!isEmpty(insights)) {
    insights.forEach((insight) => {
      if (!isEmpty(insight.marketInsightArticles)) {
        insight.marketInsightArticles.forEach((article) => {
          article.category = insight.title;
          articles.push(article);
        });
      }
    });
  }

  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(null);

  if (isEmpty(TPI)) {
    return (
      <div className="admin-background">
        <div className="project-control-container">
          <div className="project-control-column-backer">
            <div className="project-control-column">
              <div className="project-title">
                <h1 className="display-4" style={{ fontSize: "30px" }}>
                  Insights
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="admin-top"></div>
        <div>
          <div className="admin-container">
            <div className="control-container"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-background">
      <div className="project-control-container">
        <div className="project-control-column-backer">
          <div className="project-control-column">
            <div className="project-control-column-content-container">
              <div className="project-title">
                <h1 className="display-4" style={{ fontSize: "30px" }}>
                  Insights
                </h1>
              </div>

              <TenderPriceNavigation />
            </div>
            <CategoryProfile selectedTopic={selectedTopic} />
            <ArticleProfile selectedArticle={selectedArticle} />

            <div className="insights-control-panel">
              <TenderPriceIndexLocationSelect
                locations={locations}
                country={country}
                region={region}
                setRegion={setRegion}
                setCountry={setCountry}
              />
              <div className="insights-control-panel-row">
                <TenderPriceIndexingUpload
                  user={user}
                  region={region}
                  country={country}
                  setTPI={setTPI}
                  locations={locations}
                />
                <TenderPriceIndexingDelete
                  user={user}
                  setTPI={setTPI}
                  region={region}
                  country={country}
                />
                <TenderPriceIndexingCalculator TPI={TPI} />
                <LocationFactorsUpdate
                  region={region}
                  country={country}
                  locations={locationList}
                  user={user}
                  locationFactors={locationFactors}
                />
                <CurrencyExchangeUpdate
                  currencies={currencyExchange.data}
                  user={user}
                  currencyExchange={currencyExchange}
                />

                <MarketInsightsUpload setInsights={setInsights} user={user} />

                <ArticleUpload
                  selectedTopic={selectedTopic}
                  user={user}
                  setInsights={setInsights}
                  region={region}
                  country={country}
                />
                <CategoryEdit
                  selectedTopic={selectedTopic}
                  user={user}
                  setInsights={setInsights}
                />
                <CategoryDelete
                  selectedTopic={selectedTopic}
                  user={user}
                  setInsights={setInsights}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="admin-top"></div>
      <div>
        <div className="admin-container">
          <div className="control-container">
            <Switch>
              <Route
                exact
                path={"/insights"}
                render={() => <Redirect replace to={`/insights/categories`} />}
              />
              <Route
                path={"/insights/categories"}
                render={() => (
                  <MarketInsights
                    insights={insights}
                    insightsLoading={insightsLoading}
                  />
                )}
              />
              <Route
                path={"/insights/location-factors"}
                render={() => (
                  <LocationFactors
                    region={region}
                    country={country}
                    locations={locationList}
                  />
                )}
              />
              <Route
                path={"/insights/currency-exchange"}
                render={() => (
                  <CurrencyExchange currencies={currencyExchange.data} />
                )}
              />
              <Route
                path={"/insights/tender-price-index"}
                render={() => (
                  <TenderPriceIndexingManagement
                    TPI={TPI}
                    selectedLocations={selectedLocations}
                    setLocations={setLocations}
                  />
                )}
              />
              {insights.map((insight) => {
                return (
                  <Route
                    key={insight.id}
                    path={`/insights/category/${insight.id}/articles`}
                    render={() => (
                      <Insight
                        user={user}
                        insight={insight}
                        setSelectedTopic={setSelectedTopic}
                        setInsights={setInsights}
                        region={region}
                        country={country}
                      />
                    )}
                  />
                );
              })}

              {isEmpty(articles) ? (
                <div></div>
              ) : (
                articles.map((article) => {
                  return (
                    <Route
                      key={article.id}
                      path={`/insights/category/${article.market_insight_id}/article/${article.id}`}
                      render={() => (
                        <Article
                          article={article}
                          setInsights={setInsights}
                          user={user}
                          setSelectedArticle={setSelectedArticle}
                        />
                      )}
                    />
                  );
                })
              )}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(TenderPriceIndex);
