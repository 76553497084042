export function displayProjectImage(project) {
  return { documentId: project.imageId, isApi: true };
}

export function displayCompanyImage(company) {
  return { documentId: company?.imageId, isApi: true };
}

export function displayUserImage(user) {
  return { documentId: user.imageId, isApi: true };
}

export function displayInsightImage(insight) {
  return { documentId: insight.imageId, isApi: true };
}
