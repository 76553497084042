import React, { useState } from "react";
import { translate } from "../../utils/translation";
// Components
import Modal from "../modal/Modal";
import isEmpty from "../../validation/is-empty";
import CompanyEdit from "./CompanyEdit";
import CompanyEmployees from "./CompanyEmployees";
import validateFilter from "../../validation/filtering";
// API
import { deleteCompany } from "./CompanyManagement.functions";
import { useCompanyProjects } from "../../api/admin/CompanyAPI";

// Functions
import { displayCompanyImage } from "../../validation/image-clean";
import { ImageDisplay } from "../images/ImageDisplay";

import "./CompanyProfile.css";

export default function CompanyProfile(props) {
  const { company, setCompany } = props;
  const { companies } = props;
  const { show, setShow } = props;
  const { projects } = useCompanyProjects(company, show);

  return (
    <div className="company-profile">
      <div className="company-profile-title-row">
        <ImageDisplay
          image={displayCompanyImage(company)}
          imageWidth={"100px"}
          imageHeight={"100px"}
          isBezierDisabled={true}
          isContain={true}
        />
      </div>
      <div className="company-details-container">
        <div className="company-profile-details">
          <div className="company-profile-details-row">
            <div className="company-profile-details-label">
              <b>{translate("Company ID")}</b>
            </div>
            <div className="company-profile-details-text">{company.id}</div>
          </div>
          <div className="company-profile-details-row">
            <div className="company-profile-details-label">
              <b>{translate("Company Name")}</b>
            </div>
            <div className="company-profile-details-text">{company.name}</div>
          </div>
          <div className="company-profile-details-row">
            <div className="company-profile-details-label">
              <b>{translate("Email Domain(s)")}</b>
            </div>
            <div className="company-profile-details-text">
              {company.email_domain}
            </div>
          </div>
          <div className="company-profile-details-row">
            <div className="company-profile-details-label">
              <b>{translate("Company Address")}</b>
            </div>
            <div className="company-profile-details-text">
              {company.address}
            </div>
          </div>
        </div>
        <EditButton company={company} companies={companies} setShow={setShow} />
        <div className="company-profile-details-divider" />
        <UserControlButton
          company={company}
          setCompany={setCompany}
          companies={companies}
          show={show}
          setShow={setShow}
        />
      </div>
      <div className="company-profile-list-container">
        <UserList company={company} />
        <ProjectsList company={company} projects={projects} />
      </div>
      <div className="company-profile-buttons-row">
        <DeleteButton
          company={company}
          companies={companies}
          setShow={setShow}
        />
      </div>
    </div>
  );
}

function UserControlButton(props) {
  const { company, setCompany } = props;
  const { companies } = props;

  const [modal, setModal] = useState(false);

  return (
    <div>
      <Modal
        // Modal Props
        title={translate("Edit Employees")}
        Component={CompanyEmployees}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        company={company}
        setCompany={setCompany}
        companies={companies}
      />
      <button
        className="general-upload-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Edit Employees")}
      </button>
    </div>
  );
}

function EditButton(props) {
  const { company } = props;
  const { companies } = props;
  const { setShow } = props;

  const [modal, setModal] = useState(false);

  return (
    <div>
      <Modal
        // Modal Props
        title={translate("Edit Company")}
        Component={CompanyEdit}
        modal={modal}
        setModal={setModal}
        // Component Props
        show={modal}
        setShow={setModal}
        company={company}
        companies={companies}
        setParentModalShow={setShow}
      />
      <button
        className="general-upload-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {translate("Edit Company")}
      </button>
    </div>
  );
}

function DeleteButton(props) {
  const { company } = props;
  const { companies } = props;
  const { setShow } = props;

  const data = {
    companyID: company.id,
    companies: companies,
    setShow: setShow,
  };

  return (
    <button
      className="company-profile-delete-button"
      onClick={async () => {
        await deleteCompany(data);
      }}
    >
      {translate("Delete Company")}
    </button>
  );
}

function ProjectsList(props) {
  const { projects } = props;
  const { company } = props;
  const [filter, setFilter] = useState("");

  if (isEmpty(projects.data)) {
    return (
      <div className="company-profile-project-box">
        <div className="company-profile-user-title-row">
          <div className="display-4" style={{ fontSize: "25px" }}>
            {translate("Projects")}
          </div>
        </div>
      </div>
    );
  }

  let filteredProjects = projects.data[company.id].filter((p) => {
    let projectName = p.title.toLowerCase();
    if (filter === "") {
      return p;
    }

    if (projectName.includes(filter.toLowerCase())) {
      return p;
    }
    return null;
  });

  let table = [];

  // Project Title Row
  table.push(
    <div key={0} className="company-profile-user-table-row">
      <div className="project-management-avatar" />
      <div className="company-profile-user-name">
        <b>{translate("Project Title")}</b>
      </div>
    </div>
  );

  // Project Rows
  filteredProjects.forEach((project, i) => {
    project.id = project.projectId;
    table.push(
      <div key={i + 1} className="company-profile-user-table-row">
        <div className="company-profile-user-name">{project.title}</div>
      </div>
    );
  });

  return (
    <div className="company-profile-project-box">
      <div className="company-profile-user-title-row">
        <div className="display-4" style={{ fontSize: "25px" }}>
          {translate("Projects")}
        </div>
        <input
          className="company-profile-user-filter"
          placeholder={"Filter Project Title"}
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        ></input>
      </div>

      {table}
    </div>
  );
}

function UserList(props) {
  const { company } = props;
  const [filter, setFilter] = useState("");

  if (isEmpty(company.users)) {
    return (
      <div className="company-profile-user-box">
        <div className="company-profile-user-title-row">
          <div className="display-4" style={{ fontSize: "25px" }}>
            {translate("Employees")}
          </div>
        </div>
      </div>
    );
  }

  let filteredUsers = company.users.filter((p) => {
    let userName =
      validateFilter(p.name) +
      " " +
      validateFilter(p.surname) +
      " " +
      validateFilter(p.job_title);

    if (filter === "") {
      return p;
    }

    if (userName.includes(filter.toLowerCase())) {
      return p;
    }

    return null;
  });

  let table = [];

  // Heading Row
  table.push(
    <div key={0} className="company-profile-user-table-row">
      <div className="company-profile-user-name">
        <b>{translate("Full Name")}</b>
      </div>
      <div className="company-profile-user-title">
        <b>{translate("Job Title")}</b>
      </div>
    </div>
  );

  // User Rows
  filteredUsers.forEach((user, i) => {
    table.push(
      <div key={i + 1} className="company-profile-user-table-row">
        <div className="company-profile-user-name">
          {user.name + " " + user.surname}
        </div>
        <div className="company-profile-user-title">{user.job_title}</div>
      </div>
    );
  });

  return (
    <div className="company-profile-user-box">
      <div className="company-profile-user-title-row">
        <div className="display-4" style={{ fontSize: "25px" }}>
          {translate("Employees")}
        </div>
        <input
          className="company-profile-user-filter"
          placeholder={"Filter Name, Surname or Job Title"}
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
      </div>

      {table}
    </div>
  );
}
