import { tranStr } from "../../utils/translation";
import isEmpty from "../../validation/is-empty";

export function getRoleBasedColumns(user,availablecolumns) {
  let roleBasedColumns = [];
  availablecolumns?.forEach((column) => {
    
    if(isEmpty(column.visible) || column.visible){
      if (!isEmpty(column.roles)) {
        let isRoleExists = user.roles.some((rol) => column.roles.includes(rol));
        if (isRoleExists) roleBasedColumns.push(column);
      } else {
        roleBasedColumns.push(column);
      }
    }
  });
  return roleBasedColumns;
}

export function getDecoratedData(data, sensitivityLevelCodes) {
  const benchmarksArray = data.map((element) => {
    element.informationSensitivityLevelDesc = isEmpty(
      sensitivityLevelCodes.data[element.informationSensitivityLevel]
    )
      ? element.informationSensitivityLevel
      : sensitivityLevelCodes.data[element.informationSensitivityLevel].label;
    return element;
  });
  return benchmarksArray;
}

export function costPlanningColumns() {
  const columns = [
    {
      heading: tranStr("PROJECT ID"),
      key: "exclusiveProjectId",
      type: "TEXT",
      width: 180,
      isFiltered: true,
    },
    {
      heading: tranStr("PROJECT SECTOR"),
      key: "sector",
      type: "TEXT",
      width: 180,
      isFiltered: true,
    },
    {
      heading: tranStr("PROJECT SUBSECTOR"),
      key: "sub_sector",
      type: "TEXT",
      width: 180,
      isFiltered: true,
    },
    {
      heading: tranStr("PROJECT QUALITY"),
      key: "quality",
      type: "TEXT",
      width: 180,
      isFiltered: true,
    },
    {
      heading: tranStr("PROJECT TYPE"),
      key: "project_type",
      type: "TEXT",
      width: 180,
      isFiltered: true,
    },
    {
      heading: tranStr("CBS"),
      key: "standard",
      type: "TEXT",
      width: 180,
      isFiltered: true,
    },
  ];
  return columns;
}