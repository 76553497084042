import React from "react";
import generateXLSX from "../../utils/spreadsheets";
import { hasRoles } from "../../utils/roles";
import PropTypes from "prop-types";


ExportBenchmarkHeatMap.propTypes ={
  rows : PropTypes.array,
  projectName: PropTypes.string,
  columns: PropTypes.array,
  user: PropTypes.object
}

export default function ExportBenchmarkHeatMap(props) {
  const { rows, projectName, columns, user } = props;
  
  if(hasRoles(user.roles,["Client"])){
    return null;
  }

  let headings = columns.map((p) => {
    return p.project_name;
  });
  let data = rows.map((r) => {
    return r.cells.map((c) => c.field);
  });
  data.unshift(headings);
  return (
    <button
      className="estimate-export-button"
      onClick={() => {
        generateXLSX(projectName + " Heatmap Data", data);
      }}
    >
      <i className="fas fa-download"></i>
    </button>
  );
}
