import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { hasRoles } from "../../utils/roles";
import PropTypes from "prop-types";

import ArticleEdit from "../tender-price-indexing/ArticleEdit";
import ArticleDelete from "../tender-price-indexing/ArticleDelete";
import InsightCategoryPath from "../path/InsightCategoryPath";
import { ImageDisplay } from "../images/ImageDisplay";
import { displayInsightImage } from "../../validation/image-clean";

import "./Insight.css";
import isEmpty from "../../validation/is-empty";
import Notification from "../common/Notification";
import { upsert_insights_read } from "../tender-price-indexing/MarketInsights.functions";

Insight.propTypes = {
  user: PropTypes.object,
  insight: PropTypes.object,
  setInsights: PropTypes.func,
  setSelectedTopic: PropTypes.func,
  region: PropTypes.string,
  country: PropTypes.string,
};

export default function Insight(props) {
  const { user } = props;
  const { insight, setInsights } = props;
  const { setSelectedTopic } = props;
  const { region, country } = props;

  // Lift state for selected topic
  useEffect(() => {
    setSelectedTopic(insight);
  }, [insight, setSelectedTopic]);

  let articles = insight.marketInsightArticles;

  //If there are no articles,display nothing
  if (isEmpty(articles)) {
    return "There are no insight articles in this category";
  }

  //display global articles along with the selected country
  articles = articles.filter((x) => x.global === true || x.region === region);

  if (isEmpty(articles)) {
    return "There are no insight articles for the selected country";
  }

  let display = [];
  articles.forEach((article) => {
    display.push(
      <Card
        key={article.id}
        insight={insight}
        setInsights={setInsights}
        article={article}
        user={user}
        region={region}
        country={country}
      />
    );
  });

  return (
    <div>
      <InsightCategoryPath category={insight.title} categoryID={insight.id} />
      {/* <h1 className="display-4">{insight.title}</h1> */}
      <div className="articles-display">{display}</div>
    </div>
  );
}

function Card(props) {
  const { user } = props;
  const { insight, setInsights } = props;
  const { article } = props;
  const { region, country } = props;

  const articleType = article.type;

  //Format date
  let day = new Date(article.timestamp).toLocaleString("default", {
    day: "numeric",
  });
  let month = new Date(article.timestamp).toLocaleString("en-GB", {
    month: "long",
  });
  let year = new Date(article.timestamp).toLocaleString("en-GB", {
    year: "numeric",
  });

  let displayDate = day + " " + month + ", " + year;

  let URL = "";

  if (articleType === "link") {
    // Check if a URL has https://
    const regexp = /^(?:[a-z]+:)?\/\//i;
    if (regexp.test(article.document_link)) {
      URL = article.document_link;
    } else {
      URL = "//" + article.document_link;
    }
  }

  return (
    <div>
      {articleType === "file" && (
        <div className="articles-card-link-wrapper">
          <ButtonsRow
            user={user}
            article={article}
            setInsights={setInsights}
            region={region}
            country={country}
          />
          <Link
            key={article.id}
            to={`/insights/category/${insight.id}/article/${article.id}`}
            className="articles-card-link"
          >
            <InsightCard
              article={article}
              displayDate={displayDate}
              user={user}
            />
          </Link>
        </div>
      )}
      {articleType === "link" && (
        <div className="articles-card-link-wrapper">
          <ButtonsRow
            user={user}
            article={article}
            setInsights={setInsights}
            region={region}
            country={country}
          />
          <a
            key={article.id}
            href={URL}
            className="articles-card-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InsightCard
              user={user}
              article={article}
              displayDate={displayDate}
              setInsights={setInsights}
            />
          </a>
        </div>
      )}
    </div>
  );
}

InsightCard.propTypes = {
  article: PropTypes.object,
  displayDate: PropTypes.object,
  user: PropTypes.object,
};

function InsightCard(props) {
  const { article } = props;
  const { displayDate } = props;
  const { user } = props;
  const [show, setShow] = useState(true);
  return (
    <button
      key={article.id}
      className="articles-card"
      onClick={() => {
        upsert_insights_read({ userId: user.id, articleId: article.id });
        setShow(false);
      }}
    >
      {show && (
        <div className="articles-card-unread-article-notification">
          <Notification at="article" articleId={article.id} />
        </div>
      )}

      <ImageDisplay
        key={article.id}
        image={displayInsightImage(article)}
        imageWidth={"320px"}
        imageHeight={"300px"}
        isBezierDisabled={true}
      />
      <div className="articles-card-title-row">
        <div>
          <Heading text={article.title} fontSize={"18px"} />
          <SubHeading text={displayDate} fontSize={"14px"} />
        </div>
      </div>
    </button>
  );
}

ButtonsRow.propTypes = {
  article: PropTypes.object,
  setInsights: PropTypes.func,
  user: PropTypes.object,
  region: PropTypes.string,
  country: PropTypes.string,
};

function ButtonsRow(props) {
  const { user } = props;
  const { article } = props;
  const { setInsights } = props;
  const { region, country } = props;

  if (hasRoles(user.roles, ["Admin"])) {
    return (
      <div className="articles-card-button-row">
        <ArticleEdit
          isVisible
          user={user}
          selectedArticle={article}
          setInsights={setInsights}
          region={region}
          country={country}
        />
        <ArticleDelete
          isVisible
          user={user}
          selectedArticle={article}
          setInsights={setInsights}
        />
      </div>
    );
  } else {
    return null;
  }
}

Heading.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.string,
};

function Heading(props) {
  const { text } = props;
  const { fontSize } = props;
  return (
    <h1 className="display-4" style={{ fontSize: fontSize }}>
      <b>{text}</b>
    </h1>
  );
}

SubHeading.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.string,
};

function SubHeading(props) {
  const { text } = props;
  const { fontSize } = props;
  return (
    <h1 className="display-4" style={{ fontSize: fontSize }}>
      {text}
    </h1>
  );
}
